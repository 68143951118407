import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SVG from "../components/svg"


const IndexPage = function() {
  return (
    <Layout>
      <SEO title="Home" />
      <SVG />
    </Layout>
  )
}

export default IndexPage
