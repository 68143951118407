import React from "react"
import { Style } from "react-style-tag";
import {isMobile, isMobileSafari,isIOS,isAndroid} from 'react-device-detect';

import SVGImage from "../images/svg/CoronaWedding.svg";

class SVGPlace extends React.Component {

    matrix = {
        '1': { name: 'M-Father', mode: 'O', sentence: "Contraint par la restriction sur les déplacements, votre père ingénieux décide de se faire livrer via Aamzon."},
        '3': { name: 'M-Mother', mode: 'O', sentence: "Respectez les règles, portez-vos masques !"},
        '35': { name: 'F-Father', mode: 'O', sentence: "Votre père s'est découvert des talents de botaniste. Ca tourne mal."},
        '38': { name: 'F-Mother', mode: 'O', sentence: "Maman a tenté une mise-en-pli maison, le résultat est fracassant." },
        '4': { name: 'F-Uncle', mode: 'O', sentence: "Votre oncle ancien légionnaire n'a pas compris toute la subtilité de l'expression ''guerre sanitaire''. Il vient équipé." },
        '6': { name: 'F-Auntie', mode: 'O', sentence: "Votre tante portugaise n'a pas pu aller à sa séance d'épilation mensuelle. Vous la confondez avec Tonton Pedro." },
        '8': { name: 'M-Uncle', mode: 'O', sentence: "Votre oncle a retrouvé le dernier rouleau de papier toilette et le garde avec lui en porte bonheur." },
        '10': { name: 'M-Auntie', mode: 'O', sentence: "Votre tante n'a pas correctement interprété toutes les mesures barrières." },
        '11': { name: 'F-Sister', mode: 'O', sentence: "Après avoir dévalisé le stock de farine du supermarché, votre soeur comptable s'est reconvertie en boulangère" },
        '14': { name: 'M-Groom', mode: 'O', sentence: "Pris de court, le tailleur du marié n'a pu réaliser que 50% du costume." },
        '16': { name: 'M-Bride', mode: 'O', sentence: "La mariée ne rentre plus dans sa robe, après deux mois de confinement. Elle ressort donc sa seule tenue blanche: une toge romaine."},
        '18': { name: 'F-BridesMaid', mode: 'O', sentence: "Votre témoin hypocondriaque a déniché un costume 100% étanche et réapparait en cosmonaute." },
        '21': { name: 'M-BestMan', mode: 'O', sentence: "Votre témoin est resté sobre pendant toute la quarantaine. Après deux coupes, il ne tient plus très droit." },
        '23': { name: 'F-GranMa', mode: 'O', sentence: "Mamie bigleuse confond la bouteille d'eau avec le gel hydro-alcoolique. Elle est patraque." },
        '25': { name: 'F-GranPa', mode: 'O', sentence: "Trop pressé de quitter l'EPAD le temps d'un week-end, votre grand-père a oublié de se changer"},
        '28': { name: 'M-GranMa', mode: 'O', sentence: "En hissant son chien du balcon du 3ieme étage pour ses besoins journaliers, mamie a malheureusement lâché la laisse." },
        '22': { name: 'M-GranPa', mode: 'O', sentence: "Votre grand-père a mauvaise haleine. Vous lui offrez un masque."},
        '34': { name: 'M-FriendA', mode: 'O', sentence: "Suivant les prescriptions de son gouvernement, une invitée américaine a trouvé le détergent caché sous l'évier." },
        '32': { name: 'F-FriendA', mode: 'O', sentence: "Votre meilleur ami a suivi un tuto de menuiserie pendant le confinement. Vous gardez votre meilleur ami, mais il perd un bras." },
        '15': { name: 'F-FriendB', mode: 'O', sentence: "Votre ami effraie les invités à cause de ses allergies au pollen. Vous lui offrez un masque."},
        '9': { name: 'M-FriendB', mode: 'O', sentence: "Votre ami a rencontré toutes ses conquêtes Tinder pendant le confinement. Vous le mettez en quarantaine"},
        '17': { name: 'F-FriendC', mode: 'O', sentence: "Test positif ! Votre invitée vient avec un masque."},
        '20': { name: 'M-FriendC', mode: 'O', sentence: "Test positif ! Votre invité vient avec un masque."},
        '37': { name: 'F-Brother', mode: 'O', sentence: "Votre frère s'est découvert une nouvelle passion pour les chauve-souris."},
        '24': { name: 'M-Brother', mode: 'O', sentence: "Votre groupe de jazz ne peut plus faire le déplacement. Heureusement, votre frère revient tout juste de son stage de mariachi" },
        '27': { name: 'M-Cousin', mode: 'O', sentence: "Test positif ! Votre invité vient avec un masque."},
        '29': { name: 'M-Cousine', mode: 'O', sentence: "Votre cousine complotiste a décidé de faire entendre sa voix à votre mariage."},
        '31': { name: 'F-Cousine', mode: 'O', sentence: 'Votre cousine au chômage technique a découvert Netflix.'},
        '30': { name: 'F-Cousin', mode: 'O', sentence: "Votre ami italien a bravé les autorités, et débarque en Montgolfière."},
        '5': { sentence: "Vous avez passé trop de temps au buffet, reculez de 1 case."},
        '2': { sentence: "Vous avez du persil dans les dents, reculez de 1 case."},
        '7': { sentence: "La mariée acccroche sa robe dans une branche. Vous la libérez. Avancez de 2 cases."},
        '12': { sentence: "Vous maîtrisez à merveille le moonwalk, reculez de 1 case."},
        '19': { sentence: "Vous ouvrez un bar clandestin derrière la salle de reception, succès absolu. Avancez de 2 cases."},
        '36': { sentence: "Vous renversez votre verre de vin rouge sur la robe de la mariée, reculez de 5 cases."},
        '13': { sentence: "Vous croyez que le coronavirus est apparu dans un labo russe, revenez à la case départ."},
        '39': { sentence: "Votre discours aux mariés remporte un bide monumental, reculez de 1 case."},
        '26': { sentence: "Vous embarquez dans la montgolfière du cousin Italien, avancez de 4 cases."},
        '33': { sentence: "Vous tombez sur une mauvaise huître, reculez de 2 cases."},
        '40': { sentence: "Mazel tov! Votre photo de mariage est maintenant disponible ;)"},
    }

    displayMode = 'O'
    mode = "focus"
    responsive = {'mobile': {'x': 100, 'y': 100}, 'desktop': {'x': 200, 'y': 100}}
    lastactive = null

    constructor(props) {
        super(props);
        this.state = {"display": "O", "interval": null, turn: 'start'};
    }

    componentDidMount() {
        this.setState({ 'interval': setInterval(this.blink.bind(this), 1000), "responsive": (isMobile||isMobileSafari||isIOS||isAndroid)?"mobile":"desktop"});
    }

    componentWillUnmount() {
        if (this.state.interval) {
            clearInterval(this.state.interval)
        }
    }

    blink() {
        if (this.displayMode === "perfect-wedding") {
            this.displayMode = "ingame";
        }
        else {
            this.displayMode = "perfect-wedding";
        }
        this.svgRender();
    }

    modify(key, e) {
        this.matrix[key]['mode'] = 'M';
        this.mode = "focus";
        this.setState({'active': key});
    }

    throwDice(e) {
        this.setState({'turn': 'dice', 'dice': Math.floor(Math.random() * 6) + 1});
    }
    
    moveDone(e) {
        this.setState({'turn': 'input'});
    }

    validateMove(e) {
        let input = document.querySelector("#case");
        let key = input.value;
        if(this.matrix[key])
        {
            this.matrix[key]['mode'] = 'M';
            this.setState({'active': key, 'turn': 'focus'});
        } else {
            this.setState({'turn': 'wronginput', 'wronginput': key});
        }
    }

    restart(e) {
        this.setState({'turn': 'start'});
    }

    svgRender() {
        let svg = document.querySelector("#gatsby-focus-wrapper > div > main > svg");

        if(this.state.turn === 'focus')
        {
            if(this.state.active)
            {
                let k = document.querySelector('#'+this.matrix[this.state.active].name+' [data-name="O"]');
                if(k.style.display === "none")
                {
                    k = document.querySelector('#'+this.matrix[this.state.active].name+' [data-name="M"]');
                }
                if(k)
                {
                    let b = k.getBoundingClientRect();
                    let pt = svg.createSVGPoint();
                    pt.x = b.x + b.width/2;
                    pt.y = b.y + b.height/2;
                    let svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
                    svg.setAttribute("viewBox", [svgP.x-this.responsive[this.state.responsive].x/2, svgP.y-this.responsive[this.state.responsive].y/2, this.responsive[this.state.responsive].x, this.responsive[this.state.responsive].y].join(' '));
                }
                this.lastactive = this.state.active;
            }
        }
        else
        {
            svg.setAttribute("viewBox", [0, 0, 368.5, 226.77].join(' '));
        }
        
        let el = null;
        for(let x in this.matrix)
        {
            if(this.displayMode === 'perfect-wedding' && this.state.active === x && this.state.turn === "focus")
            {
                el = document.querySelector('#'+this.matrix[x].name + ' [data-name="M"]');
                if(el) {
                    el.style.display = 'none';
                }
                el = document.querySelector('#'+this.matrix[x].name + ' [data-name="O"]');
                if(el) {
                    el.style.display = 'block';
                }
            }
            else
            {
                el = document.querySelector('#'+this.matrix[x].name + ' [data-name="' + (this.matrix[x].mode==='O'?'M':'O') + '"]');
                if(el) {
                    el.style.display = 'none';
                }
                el = document.querySelector('#'+this.matrix[x].name + ' [data-name="' + (this.matrix[x].mode==='O'?'O':'M') + '"]');
                if(el) {
                    el.style.display = 'block';
                }
            }
        }
    }

    render() {
        let css_hide = "";
        let css_display = "";
        for(let x in this.matrix)
        {
            if(css_hide.length)
            {
                css_hide += ', ';
                css_display += ', ';
            }
            if(this.state.display === 'perfect-wedding')
            {
                css_hide += '#'+this.matrix[x].name + ' [data-name="M"]';
                css_display += '#'+this.matrix[x].name + ' [data-name="O"]';
            }
            else
            {
                css_hide += '#'+this.matrix[x].name + ' [data-name="' + (this.matrix[x].mode==='O'?'M':'O') + '"]';
                css_display += '#'+this.matrix[x].name + ' [data-name="' + (this.matrix[x].mode==='O'?'O':'M') + '"]';
            }
        }
        return (
            <>
                <Style>
                {`
                    [data-name="M"] { display: none; }
                    `+ css_hide + `
                    {
                        display: none;
                    }

                    `+ css_display + `
                    {
                        display: block;
                    }
                `}
                </Style>

                <div style={{textAlign:'center', padding: '1vh', minHeight: '15vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    { this.state.turn === 'start' && (<><strong>
                        <button onClick={this.throwDice.bind(this)}>Lancez le dé</button>
                    </strong></>)}

                    { this.state.turn === 'dice' && (<><strong>
                        Avancez de { this.state.dice } case{ this.state.dice > 1 && 's' }.
                        <br />
                        <button onClick={this.moveDone.bind(this)}>Suivant</button>
                    </strong></>)}

                    { this.state.turn === 'input' && (<><strong>
                        Entrez le numéro de la case : &nbsp;&nbsp;
                        <input type="number" id="case" name="case" min="1" max="100" />
                        <br />
                        <button onClick={this.validateMove.bind(this)}>Suivant</button>
                    </strong></>)}

                    { this.state.turn === 'wronginput' && (<><strong>
                        La case { this.state.wronginput } n'existe pas.
                        <br />
                        <button onClick={this.moveDone.bind(this)}>Resaisir</button>
                    </strong></>)}

                    { this.state.turn === 'focus' && (<>
                        <h1 style={{textAlign:'center', fontSize:'20px', margin:'8px'}}>{this.matrix[this.state.active].sentence}</h1>
                        <br />
                        <button onClick={this.restart.bind(this)}>Suivant</button>
                    </>)}
                </div>

                <SVGImage style={this.state.responsive==='mobile'?{width:'100%', marginLeft: '0'}:{width:'80%', marginLeft: '10%', height: 'auto', maxHeight: '85vh'}} />
            </>
        );

    }
}
export default SVGPlace